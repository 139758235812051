
import { defineComponent, ref } from "vue";

import { AvresaStation, AvresaTimetable } from "@/script/Avresa";

import AvresaService from "@/components/AvresaService.component.vue";

export default defineComponent({
    components: {
        AvresaService,
    },
    async mounted(): Promise<void> {
        this.station = await AvresaStation.get("HU");
        this.timetable = await this.station.getTimetable(new Date());

        window.addEventListener("message", (event: MessageEvent<string>) => {
            if (typeof event.data === "string" && event.data.startsWith("avresa:")) {
                this.station?.setEventData(atob(event.data.substr(7)), true);
                this.irisWindow?.close();
            }
        });

        this.irisWindow = window.open(`https://iris.noncd.db.de/wbt/js/index.html?typ=ab&bhf=${this.station.ds100}&Zeilen=30`, this.station.eva);
        setTimeout(() => {
            if (this.irisWindow != null) {
                this.irisWindow.postMessage(`avresa:${this.station?.eva}`, "https://iris.noncd.db.de/");
            }
        }, 200);
    },
    setup() {
        const station = ref<AvresaStation | null>(null);
        const timetable = ref<AvresaTimetable | null>(null);

        const irisWindow = ref<Window | null>(null);

        return {
            irisWindow,
            station,
            timetable,
        };
    },
});
