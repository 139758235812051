import axios from "axios";
import addHours from "date-fns/addHours";
import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import de from "date-fns/locale/de";
import parse from "date-fns/parse";
import subHours from "date-fns/subHours";
import { XMLParser } from "fast-xml-parser";
import he from "he";

type IrisEventData = {
    id: string,
    ar?: {
        cp?: string,
        cpth?: string,
        ct?: string,
    },
    dp?: {
        cp?: string,
        cpth?: string,
        ct?: string,
    },
};

type IrisJourneyData = {
    l?: string,
    pde?: string,
    pp: string,
    ppth: string,
    pt: string,
    tra?: string,
    wings?: string,
};

type IrisServiceData = {
    id: string,
    ar?: IrisJourneyData,
    dp?: IrisJourneyData,
    tl: {
        c: string, // Seems to be the category of service (so far: ICE, IC, S, RE, RB, ME, WFB, ENO)
        f: string, // Could be the type of service (so far: F, D, N, S)
        n: string, // Number of the service
        o: string, // Could be the operator (so far: TDHS, 80, R0, R1, W3, 800244, 800295, RSNM)
        t: string, // Could be the type of freight (so far: p)
    },
};

const parser = new XMLParser({ attributeNamePrefix: "", ignoreAttributes: false, });

export class AvresaStation {
    public events: AvresaEvent[] = [];

    constructor(
        public name: string,
        public eva: string,
        public ds100: string,
        public isDB: boolean,
    ) { }

    static async get(ds100: string): Promise<AvresaStation> {
        const response = await axios.get(`https://iris.noncd.db.de/iris-tts/timetable/station/${ds100}`);
        const data = parser.parse(response.data);

        const { name, eva, db: isDB } = data.stations.station;

        return new AvresaStation(name, eva, ds100, isDB);
    }

    async fetchEvents(full: boolean = false): Promise<void> {
        const response = await axios.get(`https://iris.noncd.db.de/iris-tts/${full ? 'f' : 'r'}chg/${this.eva}`);
        const data = parser.parse(response.data);

        const { s: events }: { s: IrisEventData[] } = data.timetable;

        if (full) {
            this.events = events.filter((ev) => !!ev.id).map(AvresaEvent.parseIrisData);
        }
    }

    getEventsUrl(full: boolean = false): string {
        return `https://iris.noncd.db.de/iris-tts/${full ? 'f' : 'r'}chg/${this.eva}`;
    }

    async getTimetable(time: Date): Promise<AvresaTimetable> {
        const date = format(time, "yyMMdd", { locale: de } );
        const services: AvresaService[] = [];

        const getHour = (time: Date) => format(time, "H", { locale: de });
        const mergeServices = (newServices: AvresaService[]) => services.push(...newServices.filter((s) => services.findIndex((s2) => s.id === s2.id) == -1));

        let timetable = await AvresaTimetable.get(this.eva, date, getHour(subHours(time, 2)));
        mergeServices(timetable.services);
        timetable = await AvresaTimetable.get(this.eva, date, getHour(subHours(time, 1)));
        mergeServices(timetable.services);
        timetable = await AvresaTimetable.get(this.eva, date, getHour(time));
        mergeServices(timetable.services);
        timetable = await AvresaTimetable.get(this.eva, date, getHour(addHours(time, 1)));
        mergeServices(timetable.services);
        timetable = await AvresaTimetable.get(this.eva, date, getHour(addHours(time, 2)));
        mergeServices(timetable.services);

        return new AvresaTimetable(services);

        // return await AvresaTimetable.get(this.eva, date, hour);
    }

    setEventData(xml: string, full: boolean = false): void {
        const data = parser.parse(xml);

        const { s: events }: { s: IrisEventData[] } = data.timetable;

        if (full) {
            this.events = events.filter((ev) => !!ev.id).map(AvresaEvent.parseIrisData);
        }
    }
}

export class AvresaTimetable {
    constructor(
        public services: AvresaService[],
    ) { }

    static async get(eva: string, date: string, hour: string): Promise<AvresaTimetable> {
        const response = await axios.get(`https://iris.noncd.db.de/iris-tts/timetable/plan/${eva}/${date}/${hour}`);
        const data: { timetable: { s: IrisServiceData[] } } = parser.parse(response.data);

        return new AvresaTimetable(data.timetable.s
            .map(AvresaService.parseIrisData)
            .sort((a, b) => {
                const timeA = a.departure?.plannedTime ?? a.arrival?.plannedTime ?? new Date();
                const timeB = b.departure?.plannedTime ?? b.arrival?.plannedTime ?? new Date();

                return compareAsc(timeA, timeB);
            })
        );
    }
}

export class AvresaService {
    public arrival?: AvresaJourney;
    public departure?: AvresaJourney;

    constructor(
        public id: string,
        public type: string,
        public number: string,
    ) { }

    static parseIrisData(data: IrisServiceData): AvresaService {
        const service = new AvresaService(data.id, he.decode(data.tl.c), data.tl.n);

        if (data.ar) {
            service.arrival = AvresaJourney.parseIrisData(data.ar);
        }

        if (data.dp) {
            service.departure = AvresaJourney.parseIrisData(data.dp);
        }

        return service;
    }
}

export class AvresaJourney {
    public line?: string;
    public plannedDestination?: string;

    constructor(
        public plannedPath: string[],
        public plannedPlatform: string,
        public plannedTime: Date,
    ) { }

    static parseIrisData(data: IrisJourneyData): AvresaJourney {
        const path = he.decode(data.ppth).split("|");
        const time = parse(data.pt, "yyMMddHHmm", new Date(), { locale: de });
        const journey = new AvresaJourney(path, he.decode(data.pp), time);

        journey.line = data.l && he.decode(data.l);
        journey.plannedDestination = data.pde;

        return journey;
    }
}

export class AvresaJourneyUpdate {
    public path?: string[];
    public platform?: string;
    public time?: Date;

    static parseIrisData(data: {
        cp?: string,
        cpth?: string,
        ct?: string,
    }): AvresaJourneyUpdate {
        const update = new AvresaJourneyUpdate();

        update.path = data.cpth !== undefined ? he.decode(data.cpth).split("|") : undefined;
        update.platform = data.cp !== undefined ? he.decode(data.cp) : undefined;
        update.time = data.ct !== undefined ? parse(data.ct, "yyMMddHHmm", new Date(), { locale: de }) : undefined;

        return update;
    }
}

export class AvresaEvent {
    public updatedArrival?: AvresaJourneyUpdate;
    public updatedDeparture?: AvresaJourneyUpdate;

    constructor(
        public serviceId: string,
    ) { }

    static parseIrisData(data: IrisEventData): AvresaEvent {
        const event = new AvresaEvent(data.id);

        event.updatedArrival = data.ar && AvresaJourneyUpdate.parseIrisData(data.ar);
        event.updatedDeparture = data.dp && AvresaJourneyUpdate.parseIrisData(data.dp);

        return event;
    }
}