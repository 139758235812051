
import { defineComponent, reactive, ref } from "vue";

import { AvresaService, AvresaStation } from "@/script/Avresa";

export default defineComponent({
    name: "AvresaService",
    props: {
        service: {
            required: true,
            type: AvresaService,
        },
        station: {
            required: true,
            type: AvresaStation,
        },
    },
    setup(props) {
        const service = reactive(props.service);
        const station = reactive(props.station);

        const previousStops = service.arrival?.plannedPath;
        const nextStops = service.departure?.plannedPath;

        const destination = ref(nextStops?.[nextStops.length - 1]);
        const events = ref(station.events.filter((ev) => ev.serviceId === service.id));
        const nextPath = ref(nextStops?.slice(0, nextStops.length - 1));
        const platform = ref(service.arrival?.plannedPlatform ?? service.departure?.plannedPlatform);
        const previousPath = ref(previousStops?.slice(1));
        const start = ref(previousStops?.[0]);
        const time = ref((service.departure?.plannedTime ?? service.arrival?.plannedTime)?.toLocaleTimeString().substr(0, 5));
        const train = ref(`${service.type} ${(service.departure?.line ?? service.arrival?.line) ?? service.number} ${service.arrival?.line || service.arrival?.line ? ` (${service.number})` : ''}`);

        const arrivalUpdate = events.value.find((event) => event.updatedArrival);
        const departureUpdate = events.value.find((event) => event.updatedDeparture);
        const platformUpdate = events.value.find((event) => (event.updatedArrival ?? event.updatedDeparture)?.platform);

        let updatedNextPath = departureUpdate?.updatedDeparture?.path;
        let updatedPreviousPath = arrivalUpdate?.updatedArrival?.path;
        let updatedPlatform = (platformUpdate?.updatedArrival ?? platformUpdate?.updatedDeparture)?.platform;
        let updatedTime = (departureUpdate?.updatedDeparture?.time ?? arrivalUpdate?.updatedArrival?.time)?.toLocaleTimeString().substr(0, 5);

        if (updatedPlatform === platform.value) {
            updatedPlatform = undefined;
        }

        if (updatedTime === time.value) {
            updatedTime = undefined;
        }

        const missingStops = ref([...(previousStops ?? []).filter((stop) => !(updatedPreviousPath ?? previousStops!).includes(stop)), ...(nextStops ?? []).filter((stop) => !(updatedNextPath ?? nextStops!)?.includes(stop))]);
        const additionalStops = ref([...(updatedPreviousPath ?? []).filter((stop) => !previousStops?.includes(stop)), ...(updatedNextPath ?? []).filter((stop) => !nextStops?.includes(stop))]);

        updatedNextPath = updatedNextPath?.slice(0, updatedNextPath.length - 1);
        updatedPreviousPath = updatedPreviousPath?.slice(1);

        const update = reactive({ nextPath: updatedNextPath, platform: updatedPlatform, previousPath: updatedPreviousPath, time: updatedTime });

        return {
            additionalStops,
            destination,
            events,
            missingStops,
            nextPath,
            platform,
            previousPath,
            service,
            start,
            station,
            time,
            train,
            update,
        };
    },
});
